import React, { useState } from 'react'
import { isAnyElementInArray } from '../../utils/arrayUtil'
import DevicesListItem from './DevicesListItem'
import './OverviewItemList.css'
import { useAppSelector } from '../../hooks'
import { Filter } from '../../modules/Mamorio'
import DetailDeviceModal from './DetailDeviceModal'

type Props = {
  group: IGroup
  filter?: Filter
}

const DevicesList = ({
  group,
  filter,
}: Props) => {
  const devices = useAppSelector((state) => state.mamorio.mamorios)
  const [selectedDevice, setSelectedDevice] = useState<IMamorio | null>(null)
  const closeModal = () => {
    setSelectedDevice(null)
  }

  const emptyItem = () => (
    <tr>
      <td colSpan={6} className="list-row">
        {
          filter && Object.values(filter)
            .some(Boolean)
            ? '検索条件に該当するMAMORIOがありません。'
            : 'このグループにはまだMAMORIOがありません。'
        }
      </td>
    </tr>
  )

  const cursor = 'pointer'

  return (
    <div className="overview-list" data-testid="overview-item-list">
      <table className="table">
        <thead className="header" data-testid="overview-item-list-header">
        <tr>
          <th>
            <div
              className="title"
              style={{ cursor }}
            >
              端末の名前
            </div>
          </th>
          <th>
            <div
              className="title"
              style={{ cursor }}
            >
              最終検知時刻
            </div>
          </th>
          <th>
            <div className="title">
              所持者
            </div>
          </th>
        </tr>
        </thead>
        <tbody className="list" data-testid="overview-item-list-body">
        {
          !devices || !isAnyElementInArray(devices)
            ? emptyItem()
            : devices.map((device: IMamorio) => DevicesListItem({
              device,
              onClickList: () => setSelectedDevice(device),
            }))
        }
        </tbody>
      </table>
      {selectedDevice && (
        <DetailDeviceModal
          modalProps={{
            device: selectedDevice,
            group,
          }}
          closeModalFunction={closeModal}
        />
      )}
    </div>
  )
}

DevicesList.defaultProps = {
  filter: undefined,
}

export default DevicesList
