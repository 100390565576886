import React from 'react'
import ConfirmModal from './ConfirmModal'
import { closeModal } from '../../modules/UI'
import ErrorModal from './ErrorModal'
import BaseModal from './BaseModal'
import DetailMamorioModal from '../overview/DetailMamorioModal'
import { useAppDispatch, useAppSelector } from '../../hooks'

export const BASE_MODAL = BaseModal
export const CONFIRM_MODAL = ConfirmModal
export const ERROR_MODAL = ErrorModal
export const MAMORIO_DETAIL_MODAL = DetailMamorioModal

type ModalProps = {
  closeModalFunction: (arg: unknown | void) => void
  modalProps: any
}

const RootModal = () => {
  const dispatch = useAppDispatch()
  const modals = useAppSelector((state) => state.ui.modals) || []

  return (
    <>
      {modals.map((modal: {
        key: string
        modalProps: any
        modalType: React.ComponentType<ModalProps>
      }) => (
        <modal.modalType
          key={modal.key}
          closeModalFunction={() => dispatch(closeModal({ modalType: modal.modalType }))}
          modalProps={modal.modalProps}
        />
      ))}
    </>
  )
}

export default RootModal
