export const AccessTokenKey = 'access_token'
export const RootGroupIdKey = 'root_group_id'

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const cookieValue = parts.pop()?.split(';').shift()
    return cookieValue !== undefined ? cookieValue : null
  }
  return null
}

const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.mamorio.jp`
}

export const getAccessToken = (): string | null => {
  let accessToken = localStorage.getItem(AccessTokenKey)

  if (!accessToken) {
    accessToken = getCookie(AccessTokenKey)
    if (accessToken) {
      deleteCookie(AccessTokenKey)
      localStorage.setItem(AccessTokenKey, accessToken)
      return accessToken
    }
    return null
  }
  return accessToken
}

export const getRootGroupId = (): GroupId => {
  const rootGroupId = localStorage.getItem(RootGroupIdKey)

  if (!rootGroupId) return NaN as GroupId

  return Number(rootGroupId) as GroupId
}

// 大阪ガス対応。後ほど削除
export const showLostAndBattery = (): boolean => {
  const groupId = getRootGroupId()
  let showLostAndBatteryGroups = []
  if (process.env.REACT_APP_BASE_PATH === 'https://user.mamorio.jp') {
    showLostAndBatteryGroups = [227, 1136, 1232]
  } else {
    showLostAndBatteryGroups = [65]
  }
  if (groupId && showLostAndBatteryGroups.includes(groupId)) {
    return true
  }
  return false
}

export const showMemo = (): boolean => {
  const groupId = getRootGroupId()
  let showLostAndBatteryGroups = []
  if (process.env.REACT_APP_BASE_PATH === 'https://user.mamorio.jp') {
    showLostAndBatteryGroups = [227, 1182, 1232, 1382, 1384]
  } else {
    showLostAndBatteryGroups = [65]
  }
  if (groupId && showLostAndBatteryGroups.includes(groupId)) {
    return true
  }
  return false
}

export const showMapOnListTop = (): boolean => {
  const groupId = getRootGroupId()
  let groups = []
  if (process.env.REACT_APP_BASE_PATH === 'https://user.mamorio.jp') {
    groups = [227, 1294]
  } else {
    groups = [65]
  }
  if (groupId && groups.includes(groupId)) {
    return true
  }
  return false
}

export const showCalender = (): boolean => {
  const groupId = getRootGroupId()
  let showGroups = []
  if (process.env.REACT_APP_BASE_PATH === 'https://user.mamorio.jp') {
    showGroups = [227, 1228, 1181, 1052, 1040, 1232]
  } else {
    showGroups = [65]
  }
  if (groupId && showGroups.includes(groupId)) {
    return true
  }
  return false
}

export const removeSession = () => {
  localStorage.removeItem(AccessTokenKey)
  localStorage.removeItem(RootGroupIdKey)
}

export const saveSession = (accessToken: string, rootGroupId: string) => {
  localStorage.setItem(AccessTokenKey, accessToken)
  localStorage.setItem(RootGroupIdKey, rootGroupId)
}
