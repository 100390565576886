import React, {
  useCallback, useEffect, useReducer, useRef,
} from 'react'
import { useParams } from 'react-router-dom'
import { GroupNavi, HeaderNavi } from '../../components/navigation'
import BaseModal from '../../components/common/BaseModal'
import Loading from '../../components/common/Loading'
import MamoriosMapModal from '../../components/overview/MamoriosMapModal'
import RentalModal from '../../components/overview/RentalModal'
import SearchBar from '../../components/overview/SearchBar'
import GroupMamorioList from '../../components/overview/GroupMamorioList'
import DevicesList from '../../components/overview/DevicesList'
import Pagination from '../../components/common/Pagination'
import { getMamoriosWithLocation } from '../../helpers/CommonMamorioHelper'
import Unauthorized from '../Unauthorized'
import {
  getGroupDevices, getGroupMamorios, getGroupMamoriosWithoutUpdatingState, SortOrderAsc, SortOrderDesc,
} from '../../modules/Mamorio'
import { getRootGroupId } from '../../modules/common/session'
import ChannelService from '../../modules/ChannelService'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { closeModal, showModal } from '../../modules/UI'
import { toId } from '../../utils/commonUtils'
import type { Filter } from '../../modules/Mamorio'
import { miniUsersIcon } from '../../icons'
import './Overview.css'
import { ERROR_MODAL } from '../../components/common/RootModal'
import { selectLoadingState } from '../../store'
import { getErrorString } from '../../utils/errorUtils'
import ReloadButton from '../../components/overview/ReloadButton'
import ViewAllMamoriosInMapButton from '../../components/overview/ViewAllMamoriosInMapButton'
import LendMamorioButton from '../../components/overview/LendMamorioButton'

enum OverviewActionType {
  Paginate = 'paginate',
  ChangeGroupId = 'changeGroupId',
  UpdateFilter = 'updateFilter',
  ListType = 'listType',
}

const MamorioListType = 'mamorio'
const DeviceListType = 'device'
type ListType = typeof MamorioListType | typeof DeviceListType

type OverviewState = {
  page?: number
  groupId?: GroupId
  filter?: Filter
  listType: ListType
}

type GruopSortDeps = {
  groupId: GroupId | null
  filter: Filter | null
  listType: ListType | null
}

type OverviewAction =
  | {
    type: OverviewActionType.Paginate,
    payload: number
  }
  | {
    type: OverviewActionType.ChangeGroupId,
    payload: GroupId
  }
  | {
    type: OverviewActionType.UpdateFilter
    payload: any
  }
  | {
    type: OverviewActionType.ListType,
    payload: ListType
  }

const reducer = (state: OverviewState, action: OverviewAction): OverviewState => {
  switch (action.type) {
    case OverviewActionType.Paginate:
      return {
        ...state,
        page: action.payload,
      }
    case OverviewActionType.ChangeGroupId:
      return {
        ...state,
        groupId: action.payload,
      }
    case OverviewActionType.UpdateFilter:
      return {
        ...state,
        filter: action.payload,
      }
    case OverviewActionType.ListType:
      return {
        ...state,
        listType: action.payload,
      }
    default:
      throw new Error()
  }
}

const Overview = () => {
  const { group_id }: { group_id: string } = useParams()

  const appDispatch = useAppDispatch()
  const team = useAppSelector((state) => state.team)
  const mamorios = useAppSelector((state) => state.mamorio.mamorios)
  const loading = useAppSelector(selectLoadingState)
  const user = useAppSelector((state) => state.user)

  const [state, dispatch] = useReducer(reducer, {
    filter: {
      order: SortOrderAsc,
      sort: SortOrderDesc,
    },
    page: 1,
    groupId: group_id ? toId<GroupId>(group_id) : getRootGroupId(),
    listType: MamorioListType,
  })

  const getCurrentGroup = () => team.groups?.find((child) => toId(child.id) === state.groupId)
  const loadList = useCallback(async (useDeviceApi: boolean) => {
    dispatch({
      type: OverviewActionType.Paginate,
      payload: 1,
    })

    if (useDeviceApi) {
      dispatch({
        type: OverviewActionType.ListType,
        payload: DeviceListType,
      })
    } else {
      dispatch({
        type: OverviewActionType.ListType,
        payload: MamorioListType,
      })
    }
  }, [dispatch])

  const reloadList = async () => {
    if (!state.groupId || !state.filter) return
    try {
      if (state.listType === DeviceListType) {
        await appDispatch(getGroupDevices({
          groupId: state.groupId,
          filter: state.filter,
        })).unwrap()
      } else {
        await appDispatch(getGroupMamorios({
          groupId: state.groupId,
          filter: state.filter,
        })).unwrap()
      }
    } catch (e: unknown) {
      appDispatch(closeModal({ modalType: ERROR_MODAL }))
      appDispatch(showModal({
        modalType: ERROR_MODAL,
        modalProps: {
          message: getErrorString(e),
        },
      }))
    }
  }

  const showMamoriosMapModal = () => {
    if (!mamorios) return

    const showMap = (_mamorios: IMamorio[]) => {
      appDispatch(showModal({
        modalType: MamoriosMapModal,
        modalProps: {
          mamorios: getMamoriosWithLocation(_mamorios),
        },
      }))
    }

    if (state.page === 1 && mamorios.length < 29) {
      showMap(mamorios)
      return
    }

    appDispatch(getGroupMamoriosWithoutUpdatingState({
      groupId: state.groupId!,
      filter: {
        page: 1,
        maxMamorios: true,
      },
    })).unwrap().then((result) => {
      showMap(result.mamorios)
    }).catch((error) => {
      console.error(getErrorString(error))
      showMap(mamorios)
    })
  }

  const showRentalModal = (group: IGroup) => {
    appDispatch(showModal({
      modalType: BaseModal,
      modalProps: {
        title: 'このグループのMAMORIOを貸し出す',
        onActionConfirmed: undefined,
        children: <RentalModal group={group} />,
      },
    }))
  }

  const updateGroupFilter = (newFilter: Filter) => {
    dispatch({
      type: OverviewActionType.UpdateFilter,
      payload: { ...state.filter, ...newFilter },
    })
  }

  const onClickHeader = async (filter: Filter, successCallback: () => void) => {
    const newFilter = { ...state.filter, ...filter }

    dispatch({
      type: OverviewActionType.UpdateFilter,
      payload: newFilter,
    })

    successCallback()
  }

  const onChangePage = (pageNumber: number) => {
    dispatch({
      type: OverviewActionType.Paginate,
      payload: pageNumber,
    })
  }

  useEffect(() => {
    dispatch({
      type: OverviewActionType.ChangeGroupId,
      payload: group_id ? toId(group_id) : getRootGroupId(),
    })
  }, [dispatch, group_id])

  useEffect(() => {
    if (typeof window.ChannelIO === 'undefined') {
      ChannelService.boot({
        pluginKey: '6182dd84-c259-45c6-9a5c-f2d1f9323ebc',
        memberId: user.user?.id,
      })
    }
  }, [user.user?.id])

  const prevDepsRef = useRef<GruopSortDeps>()

  useEffect(() => {
    if (!state.groupId || !state.filter) return

    const newDeps = {
      groupId: state.groupId,
      filter: state.filter,
      listType: state.listType,
    }

    const dependenciesChanged = JSON.stringify(prevDepsRef.current) !== JSON.stringify(newDeps)
    const currentPage = dependenciesChanged ? 1 : state.page
    if (state.listType === DeviceListType) {
      appDispatch(getGroupDevices({
        groupId: state.groupId,
        filter: { ...state.filter, page: state.page },
      }))
    } else {
      appDispatch(getGroupMamorios({
        groupId: state.groupId,
        filter: { ...state.filter, page: currentPage },
      }))
      // TODO: ソート時に2回fetchしてしまう
      if (dependenciesChanged) {
        onChangePage(1)
      }
    }
    prevDepsRef.current = newDeps
  }, [appDispatch, state.groupId, state.filter, state.listType, state.page])

  const currentGroup = getCurrentGroup()

  if (!currentGroup) {
    return (
      <div className="content">
        <HeaderNavi />
        <Unauthorized />
      </div>
    )
  }

  return (
    <div
      className="content"
      data-testid="overview"
    >
      <HeaderNavi />
      <Loading show={loading} />
      <GroupNavi>
        <>
          <div className="overview-title">
            <div className="group-name" data-testid="current-group-name">
              <img src={miniUsersIcon} alt="group-icon" />
              {currentGroup.name}
            </div>
            <div>
              <ReloadButton onClick={reloadList} />
              { mamorios
                && getMamoriosWithLocation(mamorios!)?.length > 0
                && <ViewAllMamoriosInMapButton onClick={showMamoriosMapModal} />}
              {currentGroup.mode === 'rental'
                && <LendMamorioButton onClick={() => showRentalModal(currentGroup)} />}
            </div>
          </div>
          <hr />
          <SearchBar
            rentalMode={team.groups?.find((group) => group.mode === 'rental') !== undefined}
            loadList={loadList}
            updateGroupFilter={updateGroupFilter}
          />
          {
            state.listType === MamorioListType ? (
              <GroupMamorioList
                group={currentGroup}
                filter={state.filter}
                onClickHeader={onClickHeader}
                onUpdateMamorio={reloadList}
              />
            ) : (
              <DevicesList
                filter={state.filter}
                group={currentGroup}
              />
            )
          }
        </>
        {mamorios && mamorios.length > 0
          && <Pagination onChangePage={onChangePage} />}
      </GroupNavi>
    </div>
  )
}

export default Overview
