import React, { useEffect, useState, useCallback } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
  HeatmapLayer,
  GroundOverlay,
} from '@react-google-maps/api'
import type { Libraries } from '@googlemaps/js-api-loader'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { GOOGLE_MAP_API_KEY, HOSTNAME } from '../../constants/Constants'
import './heatmap.css'
import { getFromNowAsText } from '../../helpers/timeHelper'
import { GroupNavi, HeaderNavi } from '../navigation'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getMamoriosWithLocation, getMamorioImageSrc } from '../../helpers/CommonMamorioHelper'
import {
  getTeamMamorios,
  getGroupMamorio,
  Filter,
  SortOrder,
  SortOrderAsc,
} from '../../modules/Mamorio'
import { getErrorString } from '../../utils/errorUtils'
import Loading from '../common/Loading'
import { selectLoadingState } from '../../store'
import MamorioListOnHeatmap from '../settings/mamorio/MamorioListOnHeatmap'

type Position = {
  lat: number
  lng: number
}

export type HeatmapFilter = {
  rssiInverted?: number
  precision?: number
}

const HeatmapPage = () => {
  const dispatch = useAppDispatch()
  const [map, setMap] = useState<any | null>(null)
  const group = useAppSelector((state) => state.team.groups?.[0])
  const mamorios = useAppSelector((state) => state.mamorio.mamorios)
  const loading = useAppSelector(selectLoadingState)
  const [loadSelectedMamorio, setLoadSelectedMamorio] = useState(false)
  const [selectedMamorio, setSelectedMamorio] = useState<IMamorio | null>(null)
  const [heatmapData, setHeatmapData] = useState<google.maps.visualization.WeightedLocation[]>([])
  const [heatmapRaius, setHeatmapRadius] = useState<number>(20)

  const [nameFilter, setNameFilter] = useState<string | null>(null)
  const [memoFilter, setMemoFilter] = useState<string | null>(null)

  const loadFromLocalStorage = (key: string, defaultValue: number | boolean) => {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : defaultValue
  }

  const [doRssiFilter, setDoRssiFilter] = useState<boolean>(loadFromLocalStorage('doRssiFilter', false))
  const [rssiInverted, setRssiInverted] = useState<number>(loadFromLocalStorage('rssiInverted', 100))
  const [doPrecisionFilter, setDoPrecisionFilter] = useState<boolean>(loadFromLocalStorage('doPrecisionFilter', true))
  const [precision, setPrecision] = useState<number>(loadFromLocalStorage('precision', 30))

  const [mapCenter, setMapCenter] = useState<Position>({
    lat: Number(35.7007505),
    lng: Number(139.769443),
  })
  const [zoom] = useState(16)
  const [libraries] = useState<Libraries>(['visualization'])
  const {
    isLoaded,
    loadError,
  } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  })
  const [size, setSize] = useState<undefined | google.maps.Size>(undefined)
  const infoWindowOptions = {
    pixelOffset: size,
  }
  const updateOffsetSize = () => setSize(new window.google.maps.Size(0, 0))

  const filterLogs = useCallback((mamorio: IMamorio): google.maps.visualization.WeightedLocation[] => [...mamorio.region_histories]
    .sort((a, b) => Date.parse(b.detected_at) - Date.parse(a.detected_at))
    .filter((h) => doPrecisionFilter === false || !precision || (precision && h.precision && Number(h.precision) <= precision))
    .filter((h) => doRssiFilter === false || !rssiInverted || (rssiInverted && h.rssi && Number(h.rssi) >= -rssiInverted && Number(h.rssi) !== 0.0))
    .map((h) => ({
      location: new google.maps.LatLng({
        lat: Number(h.latitude),
        lng: Number(h.longitude),
      }),
      weight: 1.0,
    })), [doPrecisionFilter, doRssiFilter, precision, rssiInverted])

  function recentPosition(mamorio: IMamorio): Position | null | undefined {
    const recentLog = mamorio.recent_log
    if (
      !recentLog
      || (doRssiFilter && (!recentLog.rssi || (Number(recentLog.rssi) <= -rssiInverted || Number(recentLog.rssi) === 0.0)))
      || (doPrecisionFilter && (!recentLog.precision || Number(recentLog.precision) > precision))
    ) {
      return null
    }

    const position = {
      lat: Number(recentLog.latitude),
      lng: Number(recentLog.longitude),
    }
    return position
  }

  function recentDetectedAt(mamorio: IMamorio): string {
    const recentLog = mamorio.recent_log
    if (!recentLog) return ''
    return getFromNowAsText(recentLog.at)
  }

  function rssiStrength(): string {
    if (rssiInverted > 76) {
      return '弱い'
    }
    if (rssiInverted > 53) {
      return '中程度'
    }
    return '強い'
  }

  useEffect(() => {
    if (!selectedMamorio) {
      setHeatmapData([])
      return
    }
    const filteredData = filterLogs(selectedMamorio)
    setHeatmapData(filteredData)
    if (filteredData[0] && filteredData[0].location) {
      setMapCenter({
        lat: filteredData[0].location.lat(),
        lng: filteredData[0].location.lng(),
      })
    }
  }, [selectedMamorio, doPrecisionFilter, doRssiFilter, precision, rssiInverted, nameFilter, memoFilter, filterLogs])

  const fetchTeamMamorios = useCallback(async (filterArg: Filter) => {
    try {
      const result = await dispatch(getTeamMamorios({
        filter: filterArg,
      })).unwrap()

      if (!result.mamorios) return
      const mamoriosHasLocation = getMamoriosWithLocation(result.mamorios) as IMamorio[]
      const recentLog = mamoriosHasLocation[0]?.recent_log
      if (!recentLog) {
        return
      }
      setMapCenter({
        lat: Number(recentLog.latitude),
        lng: Number(recentLog.longitude),
      })
    } catch (error: unknown) {
      // TODO: 例外処理
      console.error(getErrorString(error))
    }
  }, [dispatch])

  useEffect(() => {
    fetchTeamMamorios({
      teamDomain: HOSTNAME?.split('.')[0],
      sort: 'name',
      order: SortOrderAsc as SortOrder,
      page: 1,
    })
  }, [fetchTeamMamorios])

  useEffect(() => {
    const saveToLocalStorage = () => {
      localStorage.setItem('doRssiFilter', JSON.stringify(doRssiFilter))
      localStorage.setItem('rssiInverted', JSON.stringify(rssiInverted))
      localStorage.setItem('doPrecisionFilter', JSON.stringify(doPrecisionFilter))
      localStorage.setItem('precision', JSON.stringify(precision))
    }
    saveToLocalStorage()
  }, [doRssiFilter, rssiInverted, doPrecisionFilter, precision])

  const clusters = (clusterer: Clusterer): JSX.Element[] => mamorios!.filter((mamorio) => selectedMamorio === null || selectedMamorio.id === mamorio.id)
    .filter((mamorio) => nameFilter === null || nameFilter === '' || mamorio.name.includes(nameFilter))
    .filter((mamorio) => memoFilter === null || memoFilter === '' || mamorio.memo?.includes(memoFilter))
    .filter((mamorio) => recentPosition(mamorio) != null)
    .map((mamorio) => (
    <Marker
      key={`mamorios-map-pin-${mamorio.id}`}
      icon={{
        url: getMamorioImageSrc(mamorio),
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={recentPosition(mamorio)!}
      onClick={async () => {
        setLoadSelectedMamorio(true)
        const updatedMamorio = await dispatch(getGroupMamorio({ mamorio_id: mamorio.id, group_id: mamorio.group_id })).unwrap()
        if (updatedMamorio) {
          setSelectedMamorio(updatedMamorio.mamorio)
        } else {
          setSelectedMamorio(mamorio)
        }
        setLoadSelectedMamorio(false)
      }}
      clusterer={clusterer}
    >
      {selectedMamorio?.id === mamorio.id && (
        <InfoWindow
          position={recentPosition(mamorio)!}
          options={infoWindowOptions}
          onCloseClick={() => { setSelectedMamorio(null) }}
        >
          <div>
            <span>{mamorio.name}</span>
            <br />
            {
              mamorio.memo && (
                <>
                  <span>{mamorio.memo}</span>
                  <br />
                </>
              )
            }
            <span>{recentDetectedAt(mamorio)}</span>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${recentPosition(mamorio)!.lat},${recentPosition(mamorio)!.lng}`}
            >
              <span>Googleマップで見る</span>
            </a>
          </div>
        </InfoWindow>
      )}
    </Marker>
    ))
  const renderMap = () => (
    <div
      className="content heatmap"
      data-testid="overview"
    >
      <HeaderNavi />
      <Loading show={loading && !loadSelectedMamorio} />
      <GroupNavi>
        <div className="map">
          <GoogleMap
            zoom={zoom}
            mapContainerStyle={{ height: '445px' }}
            center={mapCenter}
            onLoad={(_map) => {
              setMap(_map)
              updateOffsetSize()
            }}
            onZoomChanged={() => {
              if (map?.getZoom()) {
                const currentZoom = map.getZoom()
                const metersPerPx = (156543.03392 * Math.cos((25.0 * Math.PI) / 180)) / (2 ** currentZoom)
                const bleRadius = 10.0 / metersPerPx
                setHeatmapRadius(bleRadius)
              }
            }}
          >
            {
              group?.blueprint && (
                <GroundOverlay
                  url={group.blueprint.image.url}
                  opacity={0.5}
                  bounds={{
                    north: group.blueprint.north,
                    south: group.blueprint.south,
                    east: group.blueprint.east,
                    west: group.blueprint.west,
                  }}
                />
              )
            }
            <MarkerClusterer
              averageCenter
              enableRetinaIcons
              gridSize={60}
              maxZoom={zoom}
            >
              {(clusterer) => (<>{clusters(clusterer)}</>)}
            </MarkerClusterer>
            {
              heatmapData.length > 0 && (
                <HeatmapLayer
                  data={heatmapData}
                  options={{ radius: heatmapRaius }}
                />
              )
            }
          </GoogleMap>
          <div className="heatmapConfig" style={{ padding: '20px', display: 'flex' }}>
            <div style={{ width: '300px' }}>
              <p>
                名前で検索
                <input
                  type="text"
                  onChange={(event) => setNameFilter(event.target.value)}
                />
              </p>
              <p>
                メモで検索
                <input
                  type="text"
                  onChange={(event) => setMemoFilter(event.target.value)}
                />
              </p>
            </div>
            <div style={{ width: '300px' }}>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={doRssiFilter}
                  style={{ width: '20px', marginRight: '6px' }}
                  onChange={(event) => setDoRssiFilter(event.target.checked)}
                />
                電波強度でフィルタリングする
              </p>
              <p>
                電波が
                {rssiStrength()}
                (rssiが-
                {rssiInverted}
                )
              </p>
              <input
                type="range"
                value={rssiInverted}
                style={{ maxWidth: '100%' }}
                onChange={(event) => {
                  setRssiInverted(Number(event.target.value))
                }}
                min={30}
                max={100}
                step={1}
                disabled={!doRssiFilter}
              />
            </div>
            <div style={{ width: '300px' }}>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={doPrecisionFilter}
                  style={{ width: '20px', marginRight: '6px' }}
                  onChange={(event) => setDoPrecisionFilter(event.target.checked)}
                />
                GPS誤差でフィルタリングする
              </p>
              <p>
                誤差が
                {precision}
                m以内
              </p>
              <input
                type="range"
                value={precision}
                style={{ maxWidth: '100%' }}
                onChange={(event) => setPrecision(Number(event.target.value))}
                min={1}
                max={1000}
                step={1}
                disabled={!doPrecisionFilter}
              />
            </div>
          </div>
        </div>
        {
          group && (
            <MamorioListOnHeatmap
              group={group}
              mamorios={mamorios!.filter((mamorio) => selectedMamorio === null || selectedMamorio.id === mamorio.id)
                .filter((mamorio) => nameFilter === null || nameFilter === '' || mamorio.name.includes(nameFilter))
                .filter((mamorio) => memoFilter === null || memoFilter === '' || mamorio.memo?.includes(memoFilter))
                .filter((mamorio) => recentPosition(mamorio) != null)}
            />
          )
        }
      </GroupNavi>
    </div>
  )

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : null
}

export default HeatmapPage
